import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import GalleryProovedores from '../common/GalleryProovedores'
import Header from '../common/Header'

const Proovedores = () => {
  return (

    <Container fluid className='p-0' >
      <Header urlImg='./images/headers/clientes.jpg' titulo='Empresas que representamos'/>
        <Container className='section__container'>
          <Row className='section__container--row'>
            <Col xs={12} lg={5} className='section__container--col'>
           
              <p className='qsomos__text--proovedores'>
              <strong> MC GROUP DEVELOPMENT LTD <br/>
                    HUAKEY<br/>
                    CHANGHONG HUAYI COMPRESSOR CO. LTD. <br/>
                    FSTB / HUAYU OVERSEAS INDUSTRIAL LIMITED 	<br/>	
                    ROBERTSHAW CONTROLS PRIVATE LIMITED<br/>
                    CHANGZHOU JIAYU TRADING CO. LTD. <br/>
                    BUNDY REFRIGERAÇÃO BRASIL IND COM LTD. <br/>
                    REFRICOMP IND DE COMPONENTES PARA <br/>
                    REFRIGERAÇÃO. LTD.<br/>
                    HME IBERTUBOS S.A.U. 		<br/>
                    HANGZHOU WEIGUANG ELECTRONIC CO. LTD. <br/>
                    A.H.I INTERNATIONAL CO.LTD. 	<br/>	
                    SHENZHEN Taionled Lighting CO. LTD.<br/>
                    SIERA TECHNOLOGY LIMITED 		<br/>
                    LITAMEX INVESTIMENT S.A. <br/>
                    VULKAN DO BRASIL LTDA <br/>
                    ENDUTEX IBERICA S.A.U <br/>
                    SCM GROUP SPA.<br/></strong>
             </p>
            </Col>
            <Col xs={12} lg={7} className='qsomos__img'>
              <GalleryProovedores />
            </Col>
          
          </Row>
         
        </Container>
    </Container>
    
  )
}

export default Proovedores